const Loader = () => {
	const spinnerStyle = {
		width: '40px',
		height: '40px',
		position: 'relative',
		margin: '100px auto'
	};

	const bounceStyle = {
		width: '100%',
		height: '100%',
		borderRadius: '50%',
		backgroundColor: '#333',
		opacity: 0.6,
		position: 'absolute',
		top: 0,
		left: 0,
		animation: 'sk-bounce 2.0s infinite ease-in-out'
	};

	const bounce2Style = {
		...bounceStyle,
		animationDelay: '-1.0s'
	};

	return (
		<div style={spinnerStyle}>
			<div style={bounceStyle}></div>
			<div style={bounce2Style}></div>
		</div>
	);
}

export default Loader;