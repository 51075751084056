import {LiaAngleDownSolid, LiaAngleUpSolid} from "react-icons/lia";
import {useState} from "react";

const Section = ({section, idx, activeSectionIndex, setActiveSectionIndex}) => {
	const [isTableExpanded, setIsTableExpanded] = useState(false);
	
	return (
		<>
			<section key={idx} className={`border-2 py-2 px-2 border-transparent ${activeSectionIndex === idx ? 'animate-highlight' : ''}`}>
				<h2
					className='text-xl md:text-2xl font-bold text-gray-500 flex justify-between'
					id={`section-${idx}`}
					onClick={() => {
						activeSectionIndex === idx ? setActiveSectionIndex(-1) : setActiveSectionIndex(idx)
					}}>
					<span>{section.title}</span>
					<span className='sm:hidden'>
						{activeSectionIndex === idx ?
								<LiaAngleUpSolid/>
							:
								<LiaAngleDownSolid/>
						}
					</span>
				</h2>
				<div className={`${activeSectionIndex === idx ? 'block' : 'hidden'}  sm:block`}>
					{typeof section.content === 'string' ?
						<p className={`text-justify text-sm sm:text-base 2xl:xl:max-w-5xl`}>{section.content}</p>
						:
						<>
							<div
								className={`sm:min-w-96 sm:max-w-sm text-justify text-sm sm:text-base ${activeSectionIndex === idx ? 'block' : 'hidden'}  sm:block`}>
								<p className={`text-justify text-sm sm:text-base 2xl:xl:max-w-5xl`}>
									{section.content.text}
								</p>
								<table className='w-full sm:min-w-96 sm:max-w-sm'>
									<tbody>
									{section.content.table.slice(0, (isTableExpanded ? section.content.table.length : 5)).map((item, idx) => (
										<tr className='flex' key={idx}>
											<td className='border border-gray-200 p-1 flex-1'>{item.title}</td>
											<td className='border border-gray-200 p-1 flex-1'>{item.content}</td>
										</tr>
									))}
									</tbody>
								</table>
								{section.content.table.length > 5 &&
									<div className='flex justify-end'>
										<button
											className='w-1/2 ps-1 md:2 text-start text-sm font-bold text-gray-500'
											onClick={() => setIsTableExpanded(!isTableExpanded)}>
											{isTableExpanded ? 'Show Less' : 'Show More ...'}
										</button>
									</div>
								}
							</div>
						</>
					}
				</div>
			</section>
		</>
	)
}

export default Section