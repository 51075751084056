import { BsSearch } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { FiHeart } from "react-icons/fi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {logoutUser} from "../../redux/authSlice";

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const {WishlistItemCount, CartItemCount} = useSelector((state) => state.auth.userInfo) ?? {WishlistItemCount: 0, CartItemCount: 0};
	
	const handleSearch = (e) => {
		e.preventDefault();
		const {target: {value}} = e;
		navigate(`/products/listing?search=${value}`);
	}
	
	// console.log(WishlistItemCount);
	
	return (
		<div  className='border-b border-gray-200 p-6 sticky top-0 z-20 bg-white'>
			<div className='sm:flex  justify-between items-center '>
				<div onClick={()=> navigate('/')}
					className='font-bold text-4xl text-center pb-4 sm:pb-0 text-blackish cursor-pointer'>
					Pay<span className={'text-indigo-600 italic'}>4</span>Brand
				</div>
				<div className='w-80% sm:w-[300px] md:w-[60%] relative'>
					<input type="text" placeholder='Search any product name...'
						   onChange={handleSearch}
						className='peer border-gray-200 border p-2 px-4 pr-10 rounded-lg w-full focus:outline-none focus:border-gray-700'/>
					<BsSearch size={20} className='cursor-pointer absolute right-0 top-0 mr-3 mt-3 text-gray-400 peer-focus:text-gray-800'/>
				</div>
				
				<div className='hidden lg:flex gap-4 text-gray-500 text-[30px]'>
					{isLoggedIn ? (
							<>
								<div className='cursor-pointer relative' onClick={()=> setIsMenuOpen(!isMenuOpen)}>
									<BiUser className={'hover:scale-110 '}/>
									{isMenuOpen && (
										<div
											className=' text-base absolute -left-18 top-10 flex flex-col bg-white justify-center py-4 shadow-md rounded-sm'>
											<p className={'py-1 px-6 border-b border border-transparent hover:border-black '}>Accounts</p>
											<p className={'py-1 px-6 border-b border border-transparent hover:border-black'} onClick={() => dispatch(logoutUser())}>
												Logout
											</p>
										</div>
									)}
								</div>
								<div className='cursor-pointer hover:scale-110 relative'
								     onClick={() => navigate('/wishlist')}>
									<FiHeart/>
									{WishlistItemCount > 0 && (
										<div
											className='bg-red-600 rounded-full absolute top-0 right-0 w-[18px] h-[18px] text-[12px] text-white grid place-items-center translate-x-1 -translate-y-1'>
											{WishlistItemCount}
										</div>
									)}
								</div>
								<div className='cursor-pointer hover:scale-110 relative' onClick={()=> navigate('/cart')}>
									<HiOutlineShoppingBag />
									{CartItemCount > 0 && (
										<div className='bg-red-600 rounded-full absolute top-0 right-0 w-[18px] h-[18px] text-[12px] text-white grid place-items-center translate-x-1 -translate-y-1'>
											{CartItemCount}
										</div>
									)}
								</div>
							</>
						) : (
						<div className={'flex flex-row justify-around gap-4'}>
							<button onClick={() => navigate('/register')}
							        className='border border-indigo-600 shadow-inner rounded-2xl py-2 px-3 text-[18px] text-indigo-800 hover:scale-105 transition-all duration-500'>
								Register
							</button>
							<button onClick={() => navigate('/login')}
							        className='border border-indigo-600 shadow-inner rounded-2xl py-2 px-3 text-[18px] text-indigo-800 hover:scale-105 transition-all duration-500'>
								Login
							</button>
						</div>
					)
					}
				</div>
			</div>
		</div>
	)
}

export default Header
