import {useEffect, useState} from "react";
import axios from "axios";

export const CategoriesMenu = () => {
	const [categories, setCategories] = useState([]);
	
	useEffect(() => {
		const fetchCategories = async () => {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/home/fetchCategories`);
			setCategories(response.data);
			console.log(response.data);
		}
		
		fetchCategories().then();
	}, []);
	
	if(categories.length !== 0)
	return (
		<div className="bg-white absolute z-10 w-64 py-2 shadow-2xl rounded-md ring-1 ring-black ring-opacity-5">
			<div className="menu">
				{categories.map(({name, subcategories}) => (
					<div key={name} className='relative group'>
						<button className="peer w-full text-left px-4 py-2 hover:bg-gray-200">
							{name}
						</button>
						{subcategories.length > 0 && <hr/>}
						<div
							className="hidden group-hover:block absolute left-full top-0 mt-2 w-48 rounded-sm shadow-md bg-white ring-1 ring-black ring-opacity-5">
							{subcategories.map(({name}) => (
								<button
									key={name}
									className="w-full text-left text-gray-600 text-sm px-4 py-2 hover:bg-gray-200"
								>
									{name}
								</button>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
