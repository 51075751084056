import React, { useState } from 'react';
import axios from 'axios';
const FileUpload = () => {
	const [file, setFile] = useState(null);
	const submitFile = async () => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			alert('File uploaded successfully');
		} catch (err) {
			alert('Error uploading file');
			console.error(err);
		}
	};
	
	return (
		<div className="flex flex-col items-center justify-center bg-gray-50 h-screen">
			<input type="file" onChange={(e) => setFile(e.target.files[0])} className="my-3" />
			<button onClick={submitFile} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
				Upload
			</button>
		</div>
	);
};

export default FileUpload;