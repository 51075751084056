
const filters = [
	{
		name: 'Categories',
		options: [
			{
				name: 'Mobiles',
				value: 'mobiles'
			},
			{
				name: 'Laptops',
				value: 'laptops'
			},
			{
				name: 'Tablets',
				value: 'tablets'
			},
			{
				name: 'Accessories',
				value: 'accessories'
			},
		]
	},
	{
		name: 'Brands',
		options: [
			{
				name: 'Apple',
				value: 'apple'
			},
			{
				name: 'Samsung',
				value: 'samsung'
			},
			{
				name: 'OnePlus',
				value: 'oneplus'
			},
			{
				name: 'Mi',
				value: 'mi'
			},
		]
	},
	{
		name: 'Price',
		options: [
			{
				name: 'Below ₹10,000',
				value: 'below-10000'
			},
			{
				name: '₹10,000 - ₹20,000',
				value: '10000-20000'
			},
			{
				name: '₹20,000 - ₹30,000',
				value: '20000-30000'
			},
			{
				name: '₹30,000 - ₹40,000',
				value: '30000-40000'
			},
			{
				name: '₹40,000 - ₹50,000',
				value: '40000-50000'
			},
			{
				name: 'Above ₹50,000',
				value: 'above-50000'
			},
		]
	},
	{
		name: 'Discount',
		options: [
			{
				name: '10% or more',
				value: '10'
			},
			{
				name: '20% or more',
				value: '20'
			},
			{
				name: '30% or more',
				value: '30'
			},
			{
				name: '40% or more',
				value: '40'
			},
			{
				name: '50% or more',
				value: '50'
			}
		]
	}
]

export default filters;