import {TbDeviceMobile, TbPasswordMobilePhone} from "react-icons/tb";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {PiEyeBold, PiEyeClosedBold} from "react-icons/pi";
import {useDispatch} from "react-redux";
import { registerUser } from "../../redux/authSlice";

const Register = () => {
	document.title = "Register";
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const [toggles, setToggles] = useState({
		showPass: false
	})
	
	const handleSubmit = async (e) => {
		e.preventDefault()
		const data = Object.fromEntries(new FormData(e.target));
		dispatch(registerUser(data))
		.then((action) => {
			if (registerUser.fulfilled.match(action)) {
			  // The registration was successful, redirect to the home page
			  navigate('/');
			} else {
			  // The registration failed, handle the error
			  console.error(action.error.message);
			}
		  });
	}
	
	return (
		<div className='lg:flex'>
			<div
				className='relative overflow-hidden lg:flex md:py-60 lg:w-2/3 bg-gradient-to-tr from-blue-800 to-purple-700 justify-around items-center hidden'>
				<div>
					<h1 className='text-white font-bold text-4xl 2xl:text-5xl font-sans cursor-pointer'>
						Pay
						<span className={'text-red-300 italic'}>4</span>
						Brand 🛒
					</h1>
					<p className='text-white mt-1 2xl:text-xl 2xl:mt-3.5'>Already our valuable customer?</p>
					<button
						onClick={() => navigate('/login')}
						className='block w-28 bg-white text-indigo-800 mt-4 2xl:mt-5 py-2  2xl:py-2.5 rounded-2xl font-bold mb-2 shadow-sm hover:scale-105 hover:shadow-xl transition-all duration-500'
					>
						Login
					</button>
				</div>
				{/*rings*/}
				<>
					<div
						className='absolute -bottom-32 -left-40 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -bottom-40 -left-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -top-40 -right-0 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -top-20 -right-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
				</>
			</div>
			<div className='flex lg:w-1/2 justify-center p-10 items-center bg-white'>
				<form  onSubmit={handleSubmit} className='unselectable bg-whte w-full max-w-xl'>
					<h1 className='text-gray-800 font-bold text-xl sm:text-2xl md:text-3xl mb-1'>
						Welcome to Pay
						<span className={'text-indigo-600 italic'}>4</span>
						Brand !
					</h1>
					<p className='text-sm  font-normal text-gray-600 mb-7'>
						Please fill in your details{" "}
					</p>
					<div className={'sm:flex flex-row justify-between'}>
						<div className='flex items-center border-2 py-2 px-3 rounded-2xl sm:me-2 mb-4 sm:w-1/2'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-5 w-5 text-gray-400'
								viewBox='0 0 20 20'
								fill='currentColor'
							>
								<path
									fillRule='evenodd'
									d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
									clipRule='evenodd'
								/>
							</svg>
							<input
								className='pl-2 flex-1 outline-none border-none'
								type='text'
								name='firstName'
								id='firstName'
								placeholder='First name'
								required
							/>
						</div>
						<div className='flex items-center border-2 py-2 px-3 rounded-2xl sm:ms-2 mb-4 sm:w-1/2'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-5 w-5 text-gray-400'
								viewBox='0 0 20 20'
								fill='currentColor'
							>
								<path
									fillRule='evenodd'
									d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
									clipRule='evenodd'
								/>
							</svg>
							<input
								className='pl-2 flex-1 outline-none border-none'
								type='text'
								name='lastName'
								id='lastName'
								placeholder='Last name'
							/>
						</div>
					</div>
					<div className='flex items-center border-2 py-2 px-3 rounded-2xl mb-4'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5 text-gray-400'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4'
							/>
						</svg>
						<input
							className='pl-2 flex-1 outline-none border-none'
							type='text'
							name='username'
							id='username'
							placeholder='Username'
							required
						/>
					</div>
					<div className='flex items-center border-2 py-2 px-3 rounded-2xl mb-4'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5 text-gray-400'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207'
							/>
						</svg>
						<input
							className='pl-2 flex-1 outline-none border-none'
							type='email'
							name='email'
							id='email'
							placeholder='Email Address'
							required
						/>
					</div>
					<div className={'sm:flex justify-between'}>
						<div className='flex items-center border-2 py-2 px-3 mb-4 rounded-2xl flex-1'>
							<TbDeviceMobile className={'text-gray-400'}/>
							<input
								className='hide-number-arrows pl-2 outline-none border-none'
								type='tel'
								name='phone'
								id='phone'
								pattern="\d{10}"
								maxLength={10}
								minLength={10}
								placeholder='Phone Number'
								required
							/>
						</div>
						<div className='flex items-center border-2 py-2 px-3 mb-4 rounded-2xl sm:ms-4'>
							<TbPasswordMobilePhone className={'text-gray-400'}/>
							<input
								className='hide-number-arrows pl-2 flex-1 outline-none border-none'
								type='text'
								name='otp'
								id='otp'
								maxLength={4}
								minLength={4}
								placeholder='Phone OTP'
								required
							/>
						</div>
					</div>
					<div className='flex items-center border-2 py-2 px-3 rounded-2xl'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5 text-gray-400'
							viewBox='0 0 20 20'
							fill='currentColor'
						>
							<path
								fillRule='evenodd'
								d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
								clipRule='evenodd'
							/>
						</svg>
						<input
							className='pl-2 flex-1 outline-none border-none'
							type={toggles.showPass? 'text' : 'password'}
							name='password'
							id='password'
							placeholder='Password'
							required
						/>
						<span className={`h-full px-3 cursor-pointer ${toggles.showPass?"text-indigo-600 hover:text-gray-400":"text-gray-400 hover:text-indigo-600" }`} onClick={()=> setToggles({...toggles, showPass : !toggles.showPass })}>
							{toggles.showPass ? <PiEyeBold/> : <PiEyeClosedBold/>}
						</span>
					</div>
					{/*<div>*/}
					{/*	<input type="checkbox" name='EULA' id='eula'/>*/}
					{/*	<label htmlFor="eula"> I accept the Terms of Service </label>*/}
					{/*</div>*/}
					<button
						type='submit'
						className='block cursor-pointer w-full bg-indigo-600 mt-4 py-2 sm:py-3 md:py-4 rounded-2xl text-white font-semibold mb-2 hover:bg-indigo-700'
					>
						Register
					</button>
					<span className='text-sm ml-2 '>
                     Trouble Registering?
		              <Link to="/support" className='text-blue-800 hover:text-blue-500 cursor-pointer'>
			               {'\u00A0'}Contact Support
		              </Link>
				  </span>
				</form>
			</div>
		</div>
	);
};

export default Register