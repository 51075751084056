import { useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {getUserInfo} from "../../redux/authSlice";
import Loader from "../../components/Loader";

const Cart = () => {
	const dispatch = useDispatch()
	const [loaders, setLoaders] = useState({})
	document.title = "Cart";
	const navigate = useNavigate();
	const {UserID} = JSON.parse(localStorage.getItem('user'));
	const GST = 18; //TODO: GET GST from DB based on category
	const [cart, setCart] = useState([]);
	let orignalCart = useRef()

	const calculateCart = (items) => {
		let totalMRPPrice = 0;
		let totalDiscount = 0;

		items.forEach( ({ Price, Discount, Quantity}) => {
			// console.log(`Product: ${ProductName} | MRP: ${Price} | Discount: ${Discount} | Quantity: ${Quantity} ` )
			// console.log(`MRP-Price: ${Price * Quantity} | Discount-Price: ${(Price * Quantity) * (Discount / 100) } | Discounted-Price: ${Price*Quantity - ((Price * Quantity) * (Discount / 100)) } ` )
			totalMRPPrice += (Price * Quantity);
			totalDiscount += (Price * Quantity) * (Discount / 100);
		});

		const gstAmount = (totalMRPPrice * GST / 100 );
		const subTotal = totalMRPPrice - totalDiscount;
		const total = subTotal + gstAmount;
		// console.log({totalMRPPrice, totalDiscount, subTotal, gstAmount, total});
		return { totalMRPPrice, totalDiscount, gstAmount, subTotal, total };
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		setLoaders({...loaders, page: true})
		const fetchCart = async () => {
			const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/cart/get/${UserID}`)
			const {output : {Status}} = data ?? {output: {Status: 'Error'}}
			if(Status === 'Success') {
				const {recordset} = data
				return recordset
			}else{
				console.log(data)
			}
		}
		fetchCart().then(r => {
			orignalCart.current = r ;
			const { totalMRPPrice, totalDiscount, gstAmount, subTotal, total } = calculateCart(r)
			setCart({...cart, items: r, totalMRPPrice, totalDiscount, gstAmount, subTotal, total})
			setLoaders({...loaders, page: false})
		});
	}, [UserID]);
	

	const cartRef = useRef();
	cartRef.current = cart;
	const updateCartOnServer = () => {
		// TODO: Update cart on server
		console.log('Cart updated on server with items:' , cartRef.current.items);
		cartRef.current.items.map(({ProductID, Quantity}, index) => {
			if(orignalCart.current[index].Quantity !== Quantity){
				console.log(ProductID, "updated from", orignalCart.current[index].Quantity, "to", Quantity)
				axios.post(`${process.env.REACT_APP_API_URL}/user/cart/add`, {
					"userId": UserID,
					"productId": ProductID,
					"quantity": Quantity
				}).then(r => {
					dispatch(getUserInfo())
				}).catch(e => console.log(e))
			} 
		})
		//also set quantity to zero for those which are present in origanl items but not in cart.items
		orignalCart.current.forEach(({ ProductID }, index) => {
		    const found = cartRef.current.items.find(item => item.ProductID === ProductID);
		    if (!found) {
		      console.log(ProductID, "updated from", orignalCart.current[index].Quantity, "to", 0);
		      axios.post(`${process.env.REACT_APP_API_URL}/user/cart/add`, {
		        "userId": UserID,
		        "productId": ProductID,
		        "quantity": 0
		      }).then(r => console.log(r)).catch(e => console.log(e));
		    }
		  });
	}

	const updateTimeoutId = useRef(null);
	const handleQuantityChange = (id, quantity) => {
		setLoaders({...loaders, [id]: true})
		setCart(prevCart => {
			let newCartItems = prevCart.items.filter(item => item.ProductID !== id || quantity > 0);
			if (quantity > 0) {
				newCartItems = newCartItems.map(item => {
					if (item.ProductID === id) {
						return {...item, Quantity: quantity}
					}
					return item
				})
			}
			const { totalMRPPrice, totalDiscount, gstAmount, subTotal, total } = calculateCart(newCartItems)
			return {...prevCart, items: newCartItems, totalMRPPrice, totalDiscount, gstAmount, subTotal, total}
		})
		setLoaders({...loaders, [id]: false})
	
		// Clear the previous timeout
		if (updateTimeoutId.current) {
			clearTimeout(updateTimeoutId.current);
		}
	
		// Set a new timeout to update the server after 5 seconds of no changes
		updateTimeoutId.current = setTimeout(() => {
			updateCartOnServer();
		}, 5000);
	}
	const handleCheckout = () => {
		navigate('/checkout')
	}

	useEffect(()=> console.log(cart), [cart])
	
	if(loaders.page) return <Loader />
	else
	return (
		<div>
			<section>
				<div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
					<div className="mx-auto max-w-3xl">
						<header className="text-center">
							<h1 className="text-xl font-bold text-gray-900 sm:text-3xl">Your Cart</h1>
						</header>
						
						{cart?.items?.length  > 0 ? (
								<div className="mt-8">
									<ul className="divide-y divide-gray-100 sm:divide-y-0 space-y-4">
										{cart?.items?.map(({ProductID ,ProductName, CoverImage, BrandName, Quantity}, index) => (
											<li key={index}
												className="flex flex-col sm:flex-row justify-between gap-4 items-center">
												<div className='cursor-pointer w-full max-w-xs sm:max-w-full sm:w-auto sm:flex-1 flex items-center gap-2 md:gap-3'
													onClick={()=> navigate( `/products/
													${ProductName.toLowerCase().replace(/\s+/g, "-")}
													/dp/${ProductID}`)
													}>
													<img
														src={CoverImage}
														alt={ProductName}
														className="h-16 w-16 rounded object-contain"
													/>
													
													<div>
														<h3 className="text-sm xl:text-base text-gray-900">{ProductName}</h3>
														
														<dl className="mt-0.5 space-y-px text-[10px] sm:text-[11px] md:text-xs xl:text-[13px] text-gray-600">
															<div>
																<dt className="inline">Brand:</dt>
																<dd className="inline">{BrandName}</dd>
															</div>
															
															{/*<div>
																<dt className="inline">Color:</dt>
																<dd className="inline">White</dd>
															</div>*/}
														</dl>
													</div>
												</div>
												<div style={{userSelect: 'none'}} className="flex items-center w-full max-w-xs sm:w-auto justify-between gap-2">
													<div className='border px-2'>
														<span onClick={() => {
															if(!loaders[ProductID]) handleQuantityChange(ProductID, Quantity + 1)
														}} 
														className='hover:text-indigo-600 cursor-pointer'>+</span>
														<label htmlFor="Line1Qty" className="sr-only"> Quantity </label>
														<input
															type="number"
															min="1"
															value={Quantity}
															onChange={ e => {
																handleQuantityChange(ProductID, e.target.value)
															}}
															id="Line1Qty"
															className="h-8 w-12 rounded border-gray-200 bg-gray-50 p-0 text-center text-xs text-gray-600 [-moz-appearance:_textfield] focus:outline-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
														/>
														<span onClick={() => handleQuantityChange(ProductID, Quantity - 1)} className='hover:text-indigo-600 cursor-pointer'>-</span>
													</div>
													<button
														onClick={()=> {
															if(!loaders[ProductID]) handleQuantityChange(ProductID, 0)
														}} 
														className="text-gray-600 transition hover:text-red-600">
														<span className="sr-only">Remove item</span>
														
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="h-4 w-4"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
															/>
														</svg>
													</button>
												</div>
											</li>
										
										))}
									</ul>
									
									<div className="mt-8 flex justify-end border-t border-gray-100 pt-8">
										<div className="w-screen max-w-lg space-y-4">
											<dl className="text-sm text-gray-700">
												<div className="flex justify-between">
													<dt>Subtotal</dt>
													<dd>{new Intl.NumberFormat('en-IN').format(cart?.totalMRPPrice?.toFixed(2))}</dd>
												</div>
												
												<div className="flex justify-between">
													<dt>GST <span>{GST}%</span></dt>
													<dd className='text-red-600 font-medium before:me-0.5'>{new Intl.NumberFormat('en-IN').format(cart?.gstAmount?.toFixed(2))}</dd>
												</div>
												
												<div className="flex justify-between">
													<dt>Discount</dt>
													<dd className='text-base text-green-600 font-medium before:content-["-"] before:me-0.5'>{new Intl.NumberFormat('en-IN').format(cart?.totalDiscount?.toFixed(2))}</dd>
												</div>
												
												<div className="flex justify-between text-lg font-medium">
													<dt>Total</dt>
													<dd className='before:content-["₹"] before:me-0.5'>{new Intl.NumberFormat('en-IN').format(cart?.total?.toFixed(2))}</dd>
												</div>
											</dl>
											
											{/*<div className="flex justify-end">
												<span className="inline-flex items-center justify-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-indigo-700">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="-ms-1 me-1.5 h-4 w-4">
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
														/>
													</svg>
													<p className="whitespace-nowrap text-xs">2 Discounts Applied</p>
												</span>
											</div>*/}
											<div className="flex justify-end">
												<span className="block rounded border border-indigo-600 bg-indigo-600 px-5 py-3 text-sm text-gray-100 transition hover:bg-white hover:text-indigo-600" onClick={handleCheckout}>Checkout</span>
											</div>
										</div>
									</div>
								</div>
							)
							:
								<div>No items found in cart</div>
						}
					</div>
				</div>
			</section>
		</div>
	)
}

export default Cart