import {useEffect, useState} from "react";
import axios from "axios";

const OrderSummary = () => {
	const {UserID} = JSON.parse(localStorage.getItem('user'));
	const GST = 18; //TODO: GET GST from DB based on category
	const [cart, setCart] = useState([]);
	
	const calculateCart = (items) => {
		let totalMRPPrice = 0;
		let totalDiscount = 0;
		
		items.forEach( ({Price, Discount, Quantity}) => {
			// console.log(`Product: ${ProductName} | MRP: ${Price} | Discount: ${Discount} | Quantity: ${Quantity} ` )
			// console.log(`MRP-Price: ${Price * Quantity} | Discount-Price: ${(Price * Quantity) * (Discount / 100) } | Discounted-Price: ${Price*Quantity - ((Price * Quantity) * (Discount / 100)) } ` )
			totalMRPPrice += (Price * Quantity);
			totalDiscount += (Price * Quantity) * (Discount / 100);
		});
		
		const gstAmount = (totalMRPPrice * GST / 100 );
		const subTotal = totalMRPPrice - totalDiscount;
		const total = subTotal + gstAmount;
		// console.log({totalMRPPrice, totalDiscount, subTotal, gstAmount, total});
		return { totalMRPPrice, totalDiscount, gstAmount, subTotal, total };
	}
	
	useEffect(() => {
		window.scrollTo(0, 0);
		const fetchCart = async () => {
			const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/cart/get/${UserID}`)
			const {output : {Status}} = data ?? {output: {Status: 'Error'}}
			if(Status === 'Success') {
				const {recordset} = data
				return recordset
			}else{
				console.log(data)
			}
		}
		
		fetchCart().then(r => {
			const { totalMRPPrice, totalDiscount, gstAmount, subTotal, total } = calculateCart(r)
			setCart({...cart, items: r, totalMRPPrice, totalDiscount, gstAmount, subTotal, total})
		});
	}, [UserID]);
	if (cart?.items?.length === 0) return <div className='text-center text-2xl'>Cart is Empty</div>
	return (
		<div className={'w-full px-4 py-4'}>
			<div className="space-y-4">
			<dl className="text-sm text-gray-700">
				<div className="flex justify-between">
					<dt>Subtotal</dt>
					<dd>{new Intl.NumberFormat('en-IN').format(cart?.totalMRPPrice?.toFixed(2))}</dd>
				</div>
				
				<div className="flex justify-between">
					<dt>GST <span>{GST}%</span></dt>
					<dd className='text-red-600 font-medium before:me-0.5'>{new Intl.NumberFormat('en-IN').format(cart?.gstAmount?.toFixed(2))}</dd>
				</div>
				
				<div className="flex justify-between">
					<dt>Discount</dt>
					<dd className='text-base text-green-600 font-medium before:content-["-"] before:me-0.5'>{new Intl.NumberFormat('en-IN').format(cart?.totalDiscount?.toFixed(2))}</dd>
				</div>
				
				<div className="flex justify-between text-lg font-medium">
					<dt>Total</dt>
					<dd className='before:content-["₹"] before:me-0.5'>{new Intl.NumberFormat('en-IN').format(cart?.total?.toFixed(2))}</dd>
				</div>
			</dl>
		</div>
		</div>
	)
}

export default OrderSummary