import {useEffect, useState} from "react";
import {LiaCheckSolid} from "react-icons/lia";
import {AddressComp} from "../../components/CheckoutSteps/Address";
import {LoginSignupComp} from "../../components/CheckoutSteps/LoginSignup";
import OrderSummary from "../../components/CheckoutSteps/OrderSummary";

const ProductCheckout = () => {
	document.title = "Checkout";
	const [activeStep, setActiveStep] = useState(0)
	const [isActiveStepCompleted, setIsActiveStepCompleted] = useState(false)
	
	useEffect(() => {
		window.scrollTo(0, 0)
	},[])
	
	const steps = [{
		title: 'Login or Signup',
		component: <LoginSignupComp setIsActiveStepCompleted={setIsActiveStepCompleted} setActiveStep={setActiveStep}/>
	}, {
		title: 'Delivery Address',
		component:  <AddressComp setIsActiveStepCompleted={setIsActiveStepCompleted}/>
	}, {
		title: 'Order Summary',
		component: <OrderSummary />
	}, {
		title: 'Payment Options',
	}]
	
	return (
		<div>
			<section>
				<div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
					<div className="mx-auto max-w-3xl flex flex-col gap-2">
						{steps.map(({title}, idx) => (
							<div className='border-2 rounded-lg shadow-lg' key={idx}>
								<div className={`p-3  flex ${activeStep === idx ? 'bg-indigo-600 rounded-t-lg' : 'bg-white'} ${activeStep > idx ? 'cursor-pointer' : 'cursor-auto'}`}
								     onClick={()=> activeStep > idx && setActiveStep(idx)}>
									<div
										className={`flex items-center justify-center w-12 h-12 rounded-full border-4 ${activeStep >= idx ? (activeStep === idx ? 'border-green-400' : 'border-green-600') : 'border-gray-300'}`}>
										{activeStep > idx ?
											<LiaCheckSolid className='text-2xl text-green-600'/>
											:
											<span
												className={`font-bold ${activeStep === idx ? 'text-white' : 'text-gray-300'}`}>{idx + 1}</span>
										}
									</div>
									<div
										className={`flex items-center justify-start ml-4 uppercase font-medium  ${activeStep >= idx ? (activeStep === idx ? 'text-white' : 'text-green-600') : 'text-gray-600'}`}>
										{title}
									</div>
								</div>
								<div className={`${activeStep === idx ? '' : 'bg-gray-100 p-1'}`}>
									{activeStep === idx &&
										<div className='flex flex-col'>
											{steps[idx].component}
											{isActiveStepCompleted &&
												<div className='flex justify-end bg-indigo-50 py-3 px-5'>
													<button
														className='px-4 py-2 rounded-md bg-indigo-600 text-white font-bold'
														onClick={() => setActiveStep(activeStep + 1)}>
														{activeStep === steps.length - 1 ? 'Place Order' : 'Next'}
													</button>
												</div>
											}
										</div>
									}
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</div>
	)
}

export default ProductCheckout