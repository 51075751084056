import { useState } from 'react';
import { TbInfoCircle, TbShieldCheck, TbTruckDelivery, TbTruckReturn } from 'react-icons/tb';

export const ProductPolicies = () => {
	const policies = [
		{
			title: 'Easy Returns',
			icon: <TbTruckReturn />,
			description: 'Easy 15 days return and exchange. Return Policies may vary based on products and promotions. For full details on our Returns Policies, please click here',
		},
		{
			title: 'Free Shipping',
			icon: <TbTruckDelivery />,
			description: 'Free shipping on all orders above ₹499. For orders below ₹499, we charge a nominal fee of ₹50. For more details on shipping, please click here',
		},
		{
			title: 'Warranty Covered',
			icon: <TbShieldCheck />,
			description: 'All products sold on Pay4Brand are covered under warranty. For more details on warranty, please click here',
		}
	];
	
	const [hoveredPolicyIndex, setHoveredPolicyIndex] = useState(null);
	
	return (
		<div className="flex md:flex-col justify-between gap-0.5">
			<>
				{policies.map(({ title, icon, description }, idx) => (
					<div className="flex flex-col md:flex-row items-center gap-2" key={idx}>
						<div className="flex items-center justify-center text-lg w-10 h-10 md:w-8 md:h-8 rounded-full bg-gray-100 text-gray-500">
							{icon}
						</div>
						<div className="relative flex gap-0.5">
							<span className="font-semibold text-xs md:text-base">{title}</span>
							<span className="hidden md:block text-base text-gray-600 cursor-pointer"
								onMouseEnter={() => setHoveredPolicyIndex(idx)}
								onMouseLeave={() => setHoveredPolicyIndex(null)}
								>
								<TbInfoCircle />
							</span>
							{hoveredPolicyIndex === idx && (
								<div className="absolute top-0 left-0 bg-white  z-50 shadow-md p-2 rounded-md w-72">
									<span className="text-sm text-gray-800">{description}</span>
								</div>
							)}
						</div>
					</div>
				))}
			</>
		</div>
	);
};