import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function RedirectToHomeIfLoggedIn({ children }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  
  console.log('isLoggedIn:', isLoggedIn);
  
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);
  
  if(!isLoggedIn) return children;
}

export default RedirectToHomeIfLoggedIn;