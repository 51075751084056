import {Link} from "react-router-dom";
import {NavbarLinks} from "../../data/NavBarLinks";
import {useState} from "react";
import OutsideClickHandler from 'react-outside-click-handler';

const NavBar = () => {
	const toggleReducerFunc = (acc, item) => ([item.comp] ? ({...acc, [item.text]: false}) : (acc) )

	const [toggles, setToggles] = useState(
		NavbarLinks.reduce(toggleReducerFunc, {})
	);
	
	return (
		<>
			<div className='hidden lg:block shadow-lg'>
				<div className=''>
					<div className='flex w-fit gap-10 mx-auto font-medium py-4 text-blackish uppercase'>
						{NavbarLinks.map((item, idx) => (
							item.elemType === 'button' ?
								<div key={idx}>
									<OutsideClickHandler
										onOutsideClick={() => {if (toggles?.[item.text]) setToggles({...toggles, [item.text] : !toggles[item.text] })}}>
											<button
												className='peer navbar__button relative hover:scale-105 hover:transition-all duration-500'
												onClick={() => setToggles({...toggles, [item.text] : !toggles[item.text] })}
											>
    											{item.text}
  											</button>
											{(toggles?.[item.text]) && (
												<div className={`navbar__sub-menu ${(toggles?.[item.text]) ? 'block' : 'hidden'}`}>
													<item.comp />
												</div>
											)}
									</OutsideClickHandler>
								</div>
								:
								<Link key={idx} to={item.to} className='navbar__link relative hover:scale-105 hover:transition-all duration-500'>
									{item.text}
								</Link>
						))
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default NavBar
