import {FiHeart} from "react-icons/fi";
import {HiLogout, HiOutlineShoppingBag} from "react-icons/hi";
import {IoMenuOutline} from "react-icons/io5";
import {AiOutlineHome, AiOutlineAppstore} from "react-icons/ai";
import {Link, useNavigate} from "react-router-dom";
import { useState} from "react";
import {FaAngleDown} from "react-icons/fa";
import {NavbarLinks} from "../../data/NavBarLinks";
import {useDispatch, useSelector} from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import {PiGear, PiList, PiUser} from "react-icons/pi";
import {logoutUser} from "../../redux/authSlice";
import {LuPanelBottomClose} from "react-icons/lu";

const MobNavBar = () => {
	const dispatch = useDispatch();
	const currentLocation = window.location.pathname;
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const {WishlistItemCount, CartItemCount} = useSelector((state) => state.auth.userInfo) ?? {WishlistItemCount: 0, CartItemCount: 0};
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isGridOpen, setIsGridOpen] = useState(false);
	return (
		<>
			<div className="lg:hidden fixed z-30 bottom-0 w-full bg-white left-[50%] -translate-x-[50%] px-8 py-2 sm:py-1.5 border-t-2 shadow-2xl"
				style={{userSelect: 'none'}}>
				<div className='max-w-md mx-auto'>
					<div className="lg:hidden flex flex-col">
						{isMenuOpen && (
							<OutsideClickHandler
								onOutsideClick={() => {if (isMenuOpen) setIsMenuOpen(false)}}>
								<div className="navbar__menu flex flex-col py-5 border-b-2">
									{NavbarLinks.map((item, idx) => (
										item.elemType === 'link' && (
											<Link key={idx} to={item.to}
											      className="navbar__link w-full text-center text-gray-700 hover:text-black p-1">
												{item.text}
											</Link>
										)
									))}
								</div>
							</OutsideClickHandler>
						)}
					</div>
					<div className=" flex justify-between text-[28px] py-2">
						<div className={`${isMenuOpen ? "text-indigo-600 " : ""}`}>
							{isMenuOpen ?
								<FaAngleDown onClick={() => setIsMenuOpen(false)}/>
								:
								<IoMenuOutline onClick={() => setIsMenuOpen(true)}/>
							}
						</div>
						<div className={`${!isGridOpen && currentLocation === '/cart' ? "text-indigo-600 scale-110" : ""} relative ${isLoggedIn ? 'block' : 'hidden'}`}
						     onClick={() => navigate('/cart')}>
							<HiOutlineShoppingBag/>
							{CartItemCount > 0 && (
								<div className="bg-red-600 rounded-full absolute top-0 right-0 w-[18px] h-[18px] text-[12px] text-white grid place-items-center translate-x-1 -translate-y-1 cursor-pointer ">
									{CartItemCount}
								</div>
							)}
						</div>
						<div className={`${!isGridOpen && currentLocation === '/' ? "text-indigo-600 scale-110" : ""}`} onClick={() => navigate('/')}>
							<AiOutlineHome/>
						</div>
						
						<div className={`relative ${isLoggedIn ? 'block' : 'hidden'} ${!isGridOpen && currentLocation === '/wishlist' ? "text-indigo-600 scale-110" : ""}`} onClick={() => navigate('/wishlist')}>
							<FiHeart/>
							{WishlistItemCount > 0 && (
								<div className="non-s bg-red-600 rounded-full absolute top-0 right-0 w-[18px] h-[18px] text-[12px] text-white grid place-items-center translate-x-1 -translate-y-1 cursor-pointer">
									{WishlistItemCount}
								</div>
							)}
						</div>
						<div className={`${isGridOpen ? "scale-110 text-indigo-600 transform" : ""}`} onClick={() =>isGridOpen ? setIsGridOpen(false) : setIsGridOpen(true)}>
							{isGridOpen ? <LuPanelBottomClose /> : <AiOutlineAppstore/> }
						</div>
					</div>
				</div>
			</div>
			{/*//model menu*/}
			<OutsideClickHandler onOutsideClick={() => {isGridOpen && setIsGridOpen(!isGridOpen)}}>
				<div className={`z-10 fixed inset-0 flex items-center justify-center p-4 bg-white transition-transform transform ${isGridOpen ? 'translate-y-0' : 'translate-y-full'}`}>
					{isLoggedIn ? (
						<div className='grid grid-cols-2 grid-rows-2 gap-4 text-4xl text-gray-400'>
							<div
								className="rounded-lg box-border min-w-28 min-h-28 border flex flex-col justify-center items-center">
								<PiUser/>
								<span className={'text-sm mt-1'}>Account</span>
							</div>
							<div
								className="rounded-lg box-border min-w-28 min-h-28 border flex flex-col justify-center items-center">
								<PiGear/>
								<span className={'text-sm mt-1'}>Settings</span>
							</div>
							<div className="rounded-lg box-border min-w-28 min-h-28 border flex flex-col justify-center items-center"
								onClick={() => {
									navigate('/products/listing')
									setIsGridOpen(false)
								}}>
								<PiList/>
								<span className={'text-sm mt-1'}>Categories</span>
							</div>
							<div className="rounded-lg box-border min-w-28 min-h-28 border flex flex-col justify-center items-center"
								onClick={() => {
									dispatch(logoutUser())
									setIsGridOpen(false)
									navigate('/')
								}}>
								<HiLogout/>
								<span className={'text-sm mt-1'}>Logout</span>
							</div>
						</div>
					) : (
						<div className='flex flex-col gap-4'>
								<button onClick={() => {
									navigate('/login')
									setIsGridOpen(false)
								}}
									className="flex justify-center items-center min-w-52 h-12 font-medium uppercase text-indigo-600 border border-indigo-600 rounded-lg shadow-sm shadow-indigo-600">Login
								</button>
								<button onClick={() => {
									navigate('/register')
									setIsGridOpen(false)
								}}
									className="flex justify-center items-center min-w-52 h-12 font-medium uppercase text-indigo-600 border border-indigo-600 rounded-lg shadow-sm shadow-indigo-600">Register
								</button>
							</div>
						)
					}
					{/*<div className='absolute bottom-14 p-4'>
						<button className='flex text-gray-600 items-center' onClick={() => setIsGridOpen(false)}>
							<svg className="w-5 h-5 text-gray-600 hover:text-gray-500 transition-colors duration-200"
							     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
							     stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
								      d="M6 18L18 6M6 6l12 12"/>
							</svg>
							<span>CLOSE</span>
						</button>
					</div>*/}
				</div>
			</OutsideClickHandler>
		</>
	);
};

export default MobNavBar;
