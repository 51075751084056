import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {PiEyeBold, PiEyeClosedBold} from "react-icons/pi";
import {useDispatch} from "react-redux";
import {registerUser} from "../../redux/authSlice";
import {TiBusinessCard} from "react-icons/ti";
import {IoIosKeypad} from "react-icons/io";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {MdOutlinePassword} from "react-icons/md";

const RegisterSeller = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const [toggles, setToggles] = useState({
		showPass: false
	})
	
	const handleSubmit = async (e) => {
		e.preventDefault()
		const data = Object.fromEntries(new FormData(e.target));
		dispatch(registerUser(data))
			.then((action) => {
				if (registerUser.fulfilled.match(action)) {
					// The registration was successful, redirect to the home page
					navigate('/');
				} else {
					// The registration failed, handle the error
					console.error(action.error.message);
				}
			});
	}
	
	return (
		<div className='lg:flex'>
			<div
				className='relative w-full overflow-hidden bg-gradient-to-tr from-blue-800 to-purple-700 flex justify-center items-center lg:py-24'>
				{/*rings*/}
				<>
					<div
						className='absolute -bottom-32 -left-40 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -bottom-40 -left-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -top-40 -right-0 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -top-20 -right-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
				</>
				<div
					className='flex w-fit justify-center py-20 px-10 md:py-20 md:px-12 items-center bg-white rounded-2xl backdrop-filter backdrop-blur-md bg-opacity-10 sm:bg-opacity-15 drop-shadow-2xl'>
					<form onSubmit={handleSubmit} className='unselectable w-full max-w-xl'>
						<h1 className='text-gray-50 font-bold text-xl sm:text-2xl md:text-3xl mb-1'>
							Business on Pay
							<span className={'text-red-400 italic'}>4</span>
							Brand !
						</h1>
						<p className='text-sm  font-normal text-gray-100 mb-7'>
							Please fill in your Business Details{" "}
						</p>
						<div className='flex items-center border py-3 px-3 rounded-md mb-4'>
							<TiBusinessCard className='text-lg text-gray-50'/>
							<input
								className='pl-2 flex-1 outline-none border-none bg-transparent text-white placeholder:text-gray-200'
								type='text'
								name='businessName'
								id='businessName'
								placeholder='Business Name i.e. ABC enterprises'
								required
							/>
						</div>
						<div className='flex items-center border py-3 px-3 rounded-md mb-4'>
							<IoIosKeypad className='text-lg text-gray-50'/>
							<input
								className='pl-2 flex-1 outline-none border-none bg-transparent text-white placeholder:text-gray-200'
								type='text'
								name='gstNumber'
								id='gstNumber'
								placeholder='GSTIN Number'
								required
							/>
						</div>
						<div className='flex items-center border py-3 px-3 rounded-md mb-4'>
							<HiOutlineLocationMarker className='text-lg text-gray-50'/>
							<textarea
								className='pl-2 flex-1 outline-none border-none resize-none bg-transparent text-white placeholder:text-gray-200'
								name='busnessAddress'
								id='busnessAddress'
								rows={3}
								placeholder='Business Address'
								required
							/>
						</div>
						<div className='flex items-center border py-3 px-3 rounded-md mb-4 md:mb-2'>
							<MdOutlinePassword className='text-lg text-gray-50'/>
							<input
								className='pl-2 flex-1 outline-none border-none bg-transparent text-white placeholder:text-gray-200'
								type={toggles.showPass ? 'text' : 'password'}
								name='password'
								id='password'
								placeholder='Verify Password'
								required
							/>
							<span
								className={`h-full px-3 cursor-pointer ${toggles.showPass ? "text-white hover:text-gray-200" : "text-gray-200 hover:text-white"}`}
								onClick={() => setToggles({...toggles, showPass: !toggles.showPass})}>
							{toggles.showPass ? <PiEyeBold/> : <PiEyeClosedBold/>}
						</span>
						</div>
						<div className='flex items-center px-1 gap-3'>
							<input type="checkbox" name='EULA' id='eula'/>
							<label htmlFor="eula" className=' text-gray-200'>
								<span>
									Yes I have read the{" "}<Link to='/'>Pay4Brand</Link>{" "}Business{" "}<Link to='#' className='underline text-indigo-50 hover:text-white'>Terms and Condition</Link>
								</span>
							</label>
						</div>
						<button
							className='block cursor-pointer w-full bg-indigo-600 mt-6 py-3 sm:py-4 md:py-5 rounded-2xl text-white font-bold mb-2 hover:bg-indigo-700'
							type='submit' >
							Get Started
						</button>
						<div className='text-sm text-gray-100 ml-2 space-x-2'>
							<span>Facing Trouble</span>
							<Link to="/support" className='text-gray-50 underline hover:text-blue-500 cursor-pointer'>
								Contact Support
							</Link>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default RegisterSeller