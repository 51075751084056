import {IoMdAddCircle, IoMdCheckmarkCircleOutline} from "react-icons/io";
import {useEffect, useState} from "react";
import {MdHome, MdOutlineHome, MdOutlinePhone, MdOutlineWork, MdWork} from "react-icons/md";
import {CiPhone} from "react-icons/ci";

export const AddressComp = ({setIsActiveStepCompleted}) => {
	const addresses = [
		{
			name: 'Priyanshu',
			mobile: '1234567890',
			pinCode: '123456',
			address: "Sadabad Gate",
			city: 'Hathras',
			state: 'Uttar Pradesh',
			addressType: 'Home',
		},
		{
			name: 'Priyanshu',
			mobile: '1234567890',
			pinCode: '123456',
			address: "Sadabad Gate",
			city: 'Hathras',
			state: 'Uttar Pradesh',
			addressType: 'Work',
		}
	];
	const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
	
	useEffect(() => {
		setIsActiveStepCompleted(selectedAddressIndex !== null)
	}, [selectedAddressIndex]);
	
	return (
		<>
			<div className='flex flex-col sm:flex-row items-center no-scrollbar overflow-scroll p-5 gap-4'>
				{(addresses?.length > 0) ?
						addresses.map(({name, mobile, pinCode, address, city, state, addressType}, idx) =>
							<div className={`cursor-pointer bg-white rounded-lg h-28 sm:h-36 w-full sm:w-44 shadow-inner border transition-all
								${selectedAddressIndex === idx ? 'shadow-md' : ' hover:shadow-md '}`}
								onClick={()=> setSelectedAddressIndex(idx)} key={idx}>
								<div className='flex justify-between items-center p-2 pb-1 border-b border-gray-300 text-gray-500'>
									<span className='text-[0.9rem] flex-1 font-medium '>{name}</span>
									{selectedAddressIndex === idx &&
										<span className='me-1 text-xl'><IoMdCheckmarkCircleOutline /></span>
									}
								</div>
								<div className='flex flex-col p-2 gap-1'>
									<div className='flex gap-1 text-sm text-gray-500'>
										<span className='text-lg relative top-[1px]'><MdOutlinePhone /> </span>
										<span>{mobile}</span>
									</div>
									<div className='flex gap-1 text-sm text-gray-500'>
										<span className='text-lg relative top-[1px]'>{addressType === "Home" ? <MdOutlineHome /> : <MdOutlineWork /> }</span>
										<span>{`${address}, ${city}, ${state}, ${pinCode}`}</span>
									</div>
								</div>
							</div>
						)
					:
					<div
						className='cursor-pointer flex flex-col items-center justify-center bg-white rounded-lg h-36 w-44 gap-2 shadow-inner border hover:shadow-md transition-all'>
						<span className='italic font-medium text-gray-500'>No address found</span>
						<span className='text-3xl text-gray-400'><IoMdAddCircle /></span>
					</div>
				}
			</div>
			<div className='hidden flex-col gap-2'>
				<div className='flex flex-col gap-2'>
					<div className='flex flex-col gap-2'>
						<label htmlFor="name" className='text-sm font-bold'>Name</label>
						<input
							type="text"
							name="name"
							id="name"
							className='border border-gray-300 rounded-md p-2'
							placeholder='Enter Name'
						/>
					</div>
					<div className='flex flex-col gap-2'>
						<label htmlFor="mobile" className='text-sm font-bold'>Mobile</label>
						<input type="text" name="mobile" id="mobile" className='border border-gray-300 rounded-md p-2'
						       placeholder='Enter Mobile'/>
					</div>
				</div>
				<div className='flex flex-col gap-2'>
					<div className='flex flex-col gap-2'>
						<label htmlFor="pincode" className='text-sm font-bold'>Pincode</label>
						<input type="text" name="pincode" id="pincode" className='border border-gray-300 rounded-md p-2'
						       placeholder='Enter Pincode'/>
					</div>
					<div className='flex flex-col gap-2'>
						<label htmlFor="locality" className='text-sm font-bold'>Locality</label>
						<input type="text" name="locality" id="locality"
						       className='border border-gray-300 rounded-md p-2' placeholder='Enter Locality'/>
					</div>
				</div>
				<div className='flex flex-col gap-2'>
					<div className='flex flex-col gap-2'>
						<label htmlFor="address" className='text-sm font-bold'>Address</label>
						<input type="text" name="address" id="address" className='border border-gray-300 rounded-md p-2'
						       placeholder='Enter Address'/>
					</div>
					<div className='flex flex-col gap-2'>
						<label htmlFor="city" className='text-sm font-bold'>City/District/Town</label>
						<input type="text" name="city" id="city" className='border border-gray-300 rounded-md p-2'
						       placeholder='Enter City/District/Town'/>
					</div>
				</div>
				<div className='flex flex-col gap-2'>
					<div className='flex flex-col gap-2'>
						<label htmlFor="state" className='text-sm font-bold'>State</label>
						<input type="text" name="state" id="state" className='border border-gray-300 rounded-md p-2'
						       placeholder='Enter State'/>
					</div>
					<div className='flex flex-col gap-2'>
						<label htmlFor="landmark" className='text-sm font-bold'>Landmark (Optional)</label>
						<input type="text" name="landmark" id="landmark"
						       className='border border-gray-300 rounded-md p-2' placeholder='Enter Landmark'/>
					</div>
				</div>
				<div className='flex flex-col gap-2'>
					<div className='flex flex-col gap-2'>
						<label htmlFor="alternate_mobile" className='text-sm font-bold'>Alternate Mobile
							(Optional)</label>
						<input type="text" name="alternate_mobile" id="alternate_mobile"
						       className='border border-gray-300 rounded-md p-2' placeholder='Enter Alternate Mobile'/>
					</div>
					<div className='flex flex-col gap-2'>
						<label htmlFor="address_type" className='text-sm font-bold'>Address Type</label>
						<select name="address_type" id="address_type" className='border border-gray-300 rounded-md p-2'>
							<option value="home">Home</option>
							<option value="work">Work</option>
						</select>
					</div>
				</div>
			</div>
		</>
	)
}