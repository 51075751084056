import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';


export const getUserInfo = createAsyncThunk(
    'auth/getUserInfo',
    async (userId, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/info/get/${userId}`);
            const { recordset } = data;
            localStorage.setItem('userInfo', JSON.stringify(recordset[0])); // Store user info in localStorage
            return recordset[0];
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const logoutUser = createAsyncThunk(
    'auth/logoutUser',
    async (_, { dispatch }) => {
        Cookies.remove('token');
        localStorage.removeItem('user');
        localStorage.removeItem('userInfo');
        dispatch(logOut());
    }
);

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (userData, { dispatch, rejectWithValue }) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, userData);
            const { token, recordset } = data;
            
            if (token && recordset) {
                Cookies.set('token', token, { expires: 7});
                localStorage.setItem('user', JSON.stringify(recordset[0]));
                // console.log("items on storage: ",localStorage.getItem('user'));
                // console.log("item on cookie: ",Cookies.get('token'));
                dispatch(logIn(recordset[0]));
                dispatch(getUserInfo(recordset[0].UserID));
            } else {
                return rejectWithValue(data);
            }
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (userData, {rejectWithValue }) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, userData);
            const { output } = data;
            
            if (output?.Status === 'Success') {
                loginUser(userData);
            } else {
                return rejectWithValue(data);
            }
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
export const checkUserLoggedIn = createAsyncThunk(
    'auth/checkUserLoggedIn',
    async (_, { dispatch, getState }) => {
        const token = Cookies.get('token');
        const userLocalStorage = JSON.parse(localStorage.getItem('user')); // Parse the user object from localStorage
        const userRedux = getState().auth.user; // Get the user object from Redux state

        if (token && (userLocalStorage || userRedux)) {
            // console.log('User logged in', token, userLocalStorage, userRedux);
            
            if (!userRedux) {
                // If user data is not in Redux, dispatch logIn with user data from localStorage
                dispatch(logIn(userLocalStorage));
            }
            if (!userLocalStorage) {
                // If user data is not in localStorage, store it
                localStorage.setItem('user', JSON.stringify(userRedux));
            }
            if (!userRedux || !userLocalStorage || userRedux.UserID !== userLocalStorage.UserID) {
                // If user data is not in either Redux or localStorage, or they are not in sync, fetch it
                dispatch(getUserInfo(userRedux ? userRedux.UserID : userLocalStorage.UserID));
            }
            return true;
        } else {
            console.log('User not logged in');
            // alert('User not logged in'+ token+ userLocalStorage+ userRedux)
            dispatch(logOut());
            return false;
        }
    }
);
const authSlice = createSlice({
    name: 'auth',
    initialState: { isLoggedIn: false, loading: 'idle', error: null },
    reducers: {
        logIn: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload
        },
        logOut: (state) => {
            state.isLoggedIn = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.userInfo = action.payload;
            })
            .addCase(getUserInfo.rejected, (state, action) => {
                state.error = action.error.message;
            })
            // Handle other actions...
    }
});

export const { logIn, logOut } = authSlice.actions;

export default authSlice.reducer;



