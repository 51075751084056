import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Color from 'color-thief-react'
import {useSelector} from "react-redux";
import Loader from "../../components/Loader";

const Home = () => {
	const [loaders, setLoaders] = useState({})
	const [products, setProducts] = useState({})
	const navigate = useNavigate();
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const {UserID} = useSelector((state) => state.auth.user) ?? {};
	const carouselProps = {
		showThumbs: false,
		preventMovementUntilSwipeScrollTolerance: true,
		infiniteLoop: true,
		stopOnHover: true,
		showStatus: false,
		showIndicators: true,
		autoPlay: true,
		emulateTouch: true,
		showArrows: false,
		swipeScrollTolerance: 1,
	};
	
	const slideImages = [
		{
			// url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
			url: 'https://plus.unsplash.com/premium_photo-1664201889896-6a42c19e953a?q=80&w=2136&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			caption: 'Slide 1'
		},
		{
			// url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
			url: 'https://plus.unsplash.com/premium_photo-1670509045675-af9f249b1bbe?q=80&w=2035&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			caption: 'Slide 2'
		},
		// {
			// url: 'https://img.freepik.com/free-vector/special-offer-creative-sale-banner-design_1017-16284.jpg?1&w=996&t=st=1704376949~exp=1704377549~hmac=a79ba2f1a576ca6344687c6bbbfba07a9d73b487a716b09a6696875ceaff9c2c',
			// caption: 'Slide 3'
		// },
		// {
		// 	url: 'https://www.shutterstock.com/shutterstock/photos/1916978303/display_1500/stock-vector-final-sale-yellow-banner-with-offer-details-1916978303.jpg',
		// 	caption: 'Slide 4'
		// },
	];
	
	
	useEffect(() => {
		setLoaders({...loaders, home: true})
		document.title = "Home";
		const fetchData = async () => {
			// console.log(process.env.REACT_APP_API_URL)
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/home`, {"showProductCount": "6"})
			// console.log(response)
			if (response?.data) {
				return response.data
			} else {
				return []
			}
		}
		fetchData().then(r => {
			setProducts({...r})
			setLoaders({...loaders, home: false})
		})
	}, []);
	
	const handleQuickCart = (id) => {
		if(isLoggedIn){
			axios.post(`${process.env.REACT_APP_API_URL}/user/cart/add`, {
				"userId": UserID,
				"productId": id,
				"quantity": 1
			}).then(({data}) => {
				if (data.output.Status === "Success") {
					navigate('/cart')
				} else {
					console.log(data)
				}
			})
		}else{
			navigate('/login')
		}
	};
	
	if (loaders.home) return <Loader/>
	else return (
		<div className="App">
			<div className="slide-container">
				<Carousel {...carouselProps}>
					{slideImages.map(({url}, index) => (
						<div key={index}>
							<Color src={url} format={'hex'} crossOrigin={"anonymous"}>
								{({data}) => (
									<div key={index} style={{backgroundColor: data}}
									     className='flex max-h-[50vh] bg-no-repeat bg-cover backdrop-blur-xl'>
										{/*{data + loading + error}*/}
										<img className={'object-contain'} src={url} alt="index"/>
									</div>
								)}
							</Color>
						</div>
					))}
				</Carousel>
			</div>
			<main className="my-12 text-start">
				<div className="container mx-auto px-6">
					<h3 className="text-gray-600 text-2xl font-medium">Explore</h3>
					<div className="h-64 rounded-md overflow-hidden bg-cover bg-center mt-5"
					     style={{backgroundImage: 'url(https://images.unsplash.com/photo-1537498425277-c283d32ef9db?q=80&w=2078&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)'}}>
						<div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
							<div className="px-10 max-w-xl">
								<h2 className="text-2xl text-white font-semibold">Laptops</h2>
								<p className="mt-2 text-gray-400">Explore our wide range of high-performance laptops
									designed for speed, power and performance. Ideal for both personal and professional
									use.</p>
								<button
									onClick={() => navigate('/products/listing')}
									className="flex items-center mt-4 px-3 py-2 bg-indigo-800 text-white text-sm uppercase font-medium rounded hover:bg-indigo-700 focus:outline-none focus:bg-indigo-600 transition">
									<span>Shop Now</span>
									<svg className="h-5 w-5 mx-2" fill="none" strokeLinecap="round"
									     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
									     stroke="currentColor">
										<path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div className="md:flex mt-8 md:-mx-4">
						<div className="w-full h-64 md:mx-4 rounded-md overflow-hidden bg-cover bg-center md:w-1/2"
						     style={{backgroundImage: 'url(https://images.unsplash.com/photo-1615655406736-b37c4fabf923?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)'}}>
							<div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
								<div className="px-10 max-w-xl">
									<h2 className="text-2xl text-white font-semibold">Accessories</h2>
									<p className="mt-2 text-gray-400">Browse through our collection of computer
										accessories. From keyboards and mice to speakers and headsets, we have
										everything you need to enhance your computing experience.</p>
									<button
										onClick={() => navigate('/products/listing')}
										className="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
										<span>Shop Now</span>
										<svg className="h-5 w-5 mx-2" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
										</svg>
									</button>
								</div>
							</div>
						</div>
						<div
							className="w-full h-64 mt-8 md:mx-4 rounded-md overflow-hidden bg-cover bg-center md:mt-0 md:w-1/2"
							style={{backgroundImage: "url('https://images.unsplash.com/photo-1609619385002-f40f1df9b7eb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')"}}>
							<div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
								<div className="px-10 max-w-xl">
									<h2 className="text-2xl text-white font-semibold">Software</h2>
									<p className="mt-2 text-gray-400">Discover our range of software solutions. Whether
										you need productivity software, antivirus protection, or graphic design tools,
										we've got you covered.</p>
									<button
										onClick={() => navigate('/products/listing')}
										className="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
										<span>Shop Now</span>
										<svg className="h-5 w-5 mx-2" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-16">
						<h3 className="text-gray-600 text-2xl font-medium">Top Selling</h3>
						<div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
							{products?.TopSellingProducts?.map(({ProductID, CoverImage, ProductName, Price, Discount}) => (
								<div key={ProductID}
								     className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden hover:shadow-xl hover:scale-105 hover:transition-all duration-300"
								     onClick={() => navigate(`/products/
										${ProductName.toLowerCase().replace(/\s+/g, "-")}
										/dp/${ProductID}`)
								     }
								>
									<div
										className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
										style={{backgroundImage: `url(${CoverImage})`}}>
										<button
											onClick={(event) => {
												event.stopPropagation();
												handleQuickCart(ProductID)
											}}
											className="p-2 md:p-3 rounded-full bg-indigo-600 text-white mx-5 -mb-6 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
											<svg className="h-5 w-5" fill="none" strokeLinecap="round"
											     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
											     stroke="currentColor">
												<path
													d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
											</svg>
										</button>
									</div>
									<div className="px-5 py-3">
										<h3 className="text-gray-700 uppercase">{ProductName}</h3>
										<span
											className="text-gray-500 mt-2">₹ {Price - (Price * (Discount / 100)).toPrecision(2)}</span>
									</div>
								</div>
							))}
							{/*<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://shop.zebronics.com/cdn/shop/files/ZEB-NBC-3S-MidnightBlue-pic2.jpg?v=1699945869&width=700')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">ZEB-NBC 3S</h3>
									<span className="text-gray-500 mt-2">₹ 35,990.00</span>
								</div>
							</div>
							<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://shop.zebronics.com/cdn/shop/products/Zeb-Jet-Pro-pic1.jpg?v=1659157507')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">ZEB-JET PRO</h3>
									<span className="text-gray-500 mt-2">₹ 799.00</span>
								</div>
							</div>
							<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://shop.zebronics.com/cdn/shop/products/Drip-Black-Adam-pic-pic2.jpg?v=1674474984')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">DRIP - DC COMICS BLACK ADAM EDITION</h3>
									<span className="text-gray-500 mt-2">₹ 1,999.00</span>
								</div>
							</div>
							<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://shop.zebronics.com/cdn/shop/files/ZEB-SMART-CAM-105-pic1.jpg?v=1686372771')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">ZEB-SMART CAM 105</h3>
									<span className="text-gray-500 mt-2">₹ 1,899.00</span>
								</div>
							</div>*/}
						</div>
					</div>
					<div className="mt-16">
						<h3 className="text-gray-600 text-2xl font-medium">New Arrival</h3>
						<div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
							{products?.NewArrivals?.map(({ProductID, CoverImage, ProductName, Price, Discount}) => (
								<div key={ProductID}
								     className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden hover:shadow-xl hover:scale-105 hover:transition-all duration-300"
								     onClick={() => navigate(`/products/
										${ProductName.toLowerCase().replace(/\s+/g, "-")}
										/dp/${ProductID}`)
								     }
								>
									<div
										className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
										style={{backgroundImage: `url(${CoverImage})`}}>
										<button
											className="p-2 md:p-3 rounded-full bg-indigo-600 text-white mx-5 -mb-6 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
											<svg className="h-5 w-5" fill="none" strokeLinecap="round"
											     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
											     stroke="currentColor">
												<path
													d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
											</svg>
										</button>
									</div>
									<div className="px-5 py-3">
										<h3 className="text-gray-700 uppercase">{ProductName}</h3>
										<span
											className="text-gray-500 mt-2">₹ {+Price - (Price * (Discount / 100)).toPrecision(2)}</span>
									</div>
								</div>
							))}
							{/*	<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://dlcdnwebimgs.asus.com/gain/b27cc918-0fd2-48d1-b7b4-277ced541f40/w692')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">BE19000</h3>
									<span className="text-gray-500 mt-2">₹ 40,999.00</span>
								</div>
							</div>
							<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://dlcdnwebimgs.asus.com/gain/3175815e-42f7-4cb1-9c43-c29ef4c7a859/w800')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">ProArt Studiobook 16 3D OLED (H7604)</h3>
									<span className="text-gray-500 mt-2">₹ 1,51,999.00</span>
								</div>
							</div>
							<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://dlcdnwebimgs.asus.com/gain/99d0a96f-4ea5-4be5-8a91-95c8e0908176/w800')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">Zenfone 9</h3>
									<span className="text-gray-500 mt-2">₹ 81,999.00</span>
								</div>
							</div>
							<div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
								<div className="flex items-end justify-end h-56 w-full bg-contain bg-no-repeat bg-center"
								     style={{backgroundImage: "url('https://cdn-hijpb.nitrocdn.com/VaSeTwiKFfdMbBVlPVDSwLvrfwESHeDd/assets/images/optimized/rev-90d784a/godigitalkart.com/wp-content/uploads/2023/09/2023-3.webp')"}}>
									<button
										className="p-2 rounded-full bg-indigo-600 text-white mx-5 -mb-4 hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
										<svg className="h-5 w-5" fill="none" strokeLinecap="round"
										     strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
										     stroke="currentColor">
											<path
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3">
									<h3 className="text-gray-700 uppercase">IDM Internet Download Manager</h3>
									<span className="text-gray-500 mt-2">₹ 1,599.00</span>
								</div>
							</div>*/}
						</div>
					</div>
				</div>
			</main>
		</div>
	)
}

export default Home