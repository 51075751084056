import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {RiDeleteBin6Line} from "react-icons/ri";
import Loader from "../../components/Loader";

const Wishlist = () => {
	const [loaders, setLoaders] = useState({})
	document.title = "Wishlist";
	const navigate = useNavigate();
	const {UserID} = JSON.parse(localStorage.getItem('user'));
	const [wishlist, setWishlist] = useState(null);
	
	const fetchWishlist = async () => {
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/wishlist/get/${UserID}`)
		if (response.data.output.Status === 'Success') {
			// console.log(response.data.recordset)
			return response.data.recordset
		} else {
			console.log(response.data)
		}
	}
	
	useEffect(() => {
		setLoaders({...loaders, wishlist: true})
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0)
		fetchWishlist().then(r => {
			setWishlist(r)
			setLoaders({...loaders, wishlist: false})
		});
	}, [UserID]);
	
	useEffect(() => {
		console.log(wishlist)
	}, [wishlist]);
	
	const handleRemoveFromWishlist = (ProductID) => {
		setWishlist(wishlist.filter(({ProductID: id}) => id !== ProductID))
		// axios.post(`${process.env.REACT_APP_API_URL}/user/cart/add`, {
		// 	"userId": UserID,
		// 	"productId": ProductID,
		// 	"quantity": 0
		// }).then(r => {
		// 	dispatch(getUserInfo())
		// 	fetchWishlist().then(r => {
		// 		setWishlist(r)
		// 	});
		// }).catch(e => console.log(e))
	};
	if (loaders.wishlist) return <Loader/>
	else return (
		<div>
			<section>
				<div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
					<div className="mx-auto max-w-3xl">
						<header className="text-center">
							<h1 className="text-xl font-bold text-gray-900 sm:text-3xl">Your Wishlist</h1>
						</header>
						<ul className="divide-y divide-gray-100 sm:divide-y-0 space-y-4">
							{wishlist && wishlist.length > 0 ?
								wishlist.map(({ProductID, ProductName, ProductDescription, CoverImage, BrandName, DateAdded}, idx) => (
									<li key={idx} className="flex flex-col sm:flex-row relative shadow rounded-md hover:rounded-lg hover:shadow-lg transition-all justify-between">
										<div className='cursor-pointer w-full sm:flex-1 flex items-center gap-2 md:gap-3 p-2'
											onClick={() => navigate(`/products/${ProductName.toLowerCase().replace(/\s+/g, "-")}/dp/${ProductID}`)}>
											<img
												src={CoverImage}
												alt={ProductName}
												className="shadow-inner h-24 w-24 rounded object-contain"
											/>
											<div>
												<h3 className="text-sm xl:text-base text-gray-900">{ProductName}</h3>
												<dl className="mt-0.5 space-y-px text-[10px] sm:text-[11px] md:text-xs xl:text-[13px] text-gray-600">
													<div>
														<dt className="inline">Brand:</dt>
														<dd className="inline">{BrandName}</dd>
													</div>
												</dl>
											</div>
										</div>
										<div className='w-full sm:w-auto flex sm:flex-col justify-between items-end px-2 pb-2 md:p-2 text-xs sm:text-sm'>
											<span className='text-gray-600'>Added: {moment(DateAdded.replace("Z", "")).fromNow()}</span>
											<div onClick={() => handleRemoveFromWishlist(ProductID)}
												className='cursor-pointer drop-shadow-2xl text-sm sm:text-base px-1 text-red-600 flex items-center gap-0.5'>
												<RiDeleteBin6Line />
												<span>Remove</span>
											</div>
										</div>
									</li>
								))
								:
								<div className="mt-8">
									<p>Nothing is added yet int the Wishlist</p>
								</div>
							}
						</ul>
					</div>
				</div>
			</section>
		</div>
)
}

export default Wishlist