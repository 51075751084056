import {TbPasswordMobilePhone} from "react-icons/tb";
import {Link, useNavigate} from "react-router-dom";
import {PiEyeBold, PiEyeClosedBold} from "react-icons/pi";
import {useState} from "react";
import {loginUser} from "../../redux/authSlice";
import {useDispatch} from "react-redux";

const Login = () => {
	document.title = "Login";
	window.scrollTo(0, 0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [toggles, setToggles] = useState({
		showPass: false
	})
	
	const handleSubmit = async (e) => {
		e.preventDefault()
		const data = Object.fromEntries(new FormData(e.target));
		dispatch(loginUser(data))
			.then((action) => {
				if (loginUser.fulfilled.match(action)) {
					// The registration was successful, redirect to the home page
					navigate('/');
				} else {
					// The registration failed, handle the error
					console.error(action.error.message);
				}
			});
	}
	
	return (
		<div className='md:flex'>
			<div className='flex md:w-1/2 justify-center p-10 items-center bg-white'>
				<form onSubmit={handleSubmit} className='bg-whte w-full max-w-sm'>
					<h1 className='text-gray-800 font-bold text-xl sm:text-2xl md:text-3xl mb-1'>
						Welcome Back to Pay
						<span className={'text-indigo-600 italic'}>4</span>
						Brand!
					</h1>
					<p className='text-sm  font-normal text-gray-600 mb-4'>
						Enter Your credentials to Continue
					</p>
					<div className='flex items-center border-2 py-2 px-3 mb-4 rounded-2xl flex-1'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5 text-gray-400'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207'
							/>
						</svg>
						<input
							className='pl-2 flex-1 outline-none border-none'
							type='text'
							name='email'
							id='email'
							placeholder='Email Address'
						/>
					</div>
					<div className='flex items-center border-2 py-2 px-3 mb-4 rounded-2xl relative'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5 text-gray-400'
							viewBox='0 0 20 20'
							fill='currentColor'
						>
							<path
								fillRule='evenodd'
								d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
								clipRule='evenodd'
							/>
						</svg>
						<input
							className='pl-2 flex-1 outline-none border-none'
							type={toggles.showPass ? 'text' : 'password'}
							name='password'
							id='password'
							placeholder='Password'
						/>
						<span
							className={`h-full px-3 cursor-pointer ${toggles.showPass ? "text-indigo-600 hover:text-gray-400" : "text-gray-400 hover:text-indigo-600"}`}
							onClick={() => setToggles({...toggles, showPass: !toggles.showPass})}>
							{toggles.showPass ? <PiEyeBold/> : <PiEyeClosedBold/>}
						</span>
					</div>
					<div className='flex items-center border-2 py-2 px-3 mb-2 rounded-2xl'>
						<TbPasswordMobilePhone className={'text-gray-400'}/>
						<input
							className='pl-2 flex-1 outline-none border-none'
							type='text'
							name=''
							id=''
							placeholder='OTP'
						/>
						<button className='hidden sm:block text-indigo-600 font-semibold me-2 text-sm '>Send OTP
						</button>
					</div>
					<div>
						<button className='block sm:hidden text-indigo-600 font-semibold me-2 text-sm mb-4 ms-1'>Send
							OTP
						</button>
					</div>
					
					<div className='sm:flex items-center justify-between px-1.5'>
						{/*<div className='flex items-center mb-1 mt-2 sm:mb-2'>
							<input type='checkbox' name='store' id='store' className='me-1 sm:me-2'/>
							<label className='text-sm' htmlFor='store'>Stay Logged In?</label>
						</div>*/}
						<Link to='/forgot-password'
						      className='text-sm text-blue-800 hover:text-blue-500 cursor-pointer  mb-2'>
							Forgot Password?
						</Link>
					</div>
					<button
						type='submit'
						className='block cursor-pointer w-full bg-indigo-600 mt-4 py-2 sm:py-3 md:py-4 rounded-2xl text-white font-semibold mb-2 hover:bg-indigo-700'
					>
						Login
					</button>
					<span className='text-sm ml-2 '>
                     Trouble Login?
		              <Link to="/support" className='text-blue-800 hover:text-blue-500 cursor-pointer'>
			               {'\u00A0'}Contact Support
		              </Link>
				  </span>
				</form>
			</div>
			<div
				className='relative overflow-hidden md:flex md:py-60 md:w-1/2 lg:w-2/3 bg-gradient-to-tr from-blue-800 to-purple-700 i justify-around items-center hidden'>
				<div>
					<p className='text-white ms-0.5 mt-1 2xl:text-xl 2xl:mt-3.5'>New to</p>
					<h1 className='text-white font-bold text-4xl 2xl:text-5xl font-sans cursor-pointer'>
						Pay
						<span className={'text-red-300 italic'}>4</span>
						Brand 🛒
					</h1>
					<button
						onClick={() => navigate('/register')}
						className='block w-28 bg-white text-indigo-800 mt-4 2xl:mt-5 py-2  2xl:py-2.5 rounded-2xl font-bold ms-1 mb-2 shadow-sm hover:scale-105 hover:shadow-xl  transition-all duration-500'
					>
						Register
					</button>
				</div>
				{/*rings*/}
				<>
					<div
						className='absolute -top-32 -left-32 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -top-40 -left-14 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -bottom-40 -right-0 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
					<div
						className='absolute -bottom-20 -right-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8'/>
				</>
			</div>
		</div>
	);
};

export default Login