import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import {TbHeart, TbShare, TbShoppingCartBolt, TbShoppingCartPlus} from "react-icons/tb";
import ProductDetail from "../../components/ProductDetail";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import { isMobile } from 'react-device-detect';
import {ProductPolicies} from "../../components/ProductDetail/ProductPolicies";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfo} from "../../redux/authSlice";

const Product = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { title, id } = useParams();
	document.title = "Product | "+title;
	const [quantity, setQuantity] = useState(1);
	const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
	const [product, setProduct] = useState(null)
	const { ProductName, BrandName, Price, Discount, imgURLs, StockQuantity, ProductDescription } = product || {};
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const { UserID } = useSelector((state) => state.auth.user) || {};
	
	
	useEffect(() => {
		window.scrollTo(0, 0)
		const fetchProduct = async () => {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`)
			if(response.status !== 200) return console.log(response)
			return (response.data)
		}
		fetchProduct().then(r=>setProduct(r)).catch(e=>console.log(e))
	}, [id]);
	
	
	const prodCarouselProps = {
		selectedItem: selectedImageIndex,
		showThumbs: false,
		showStatus: false,
		showArrows: false,
		infiniteLoop: true,
		emulateTouch: true,
		onChange: (index) => setSelectedImageIndex(index),
	};
	
	if(!product) return <div>Loading...</div>
	
	const handleAddToCart = async () => {
		if(isLoggedIn){
			const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/user/cart/add`, {
				"userId": UserID,
				"productId": id,
				"quantity": quantity
			})
			if (data.output.Status === "Success") {
				navigate('/cart')
			} else {
				console.log(data)
			}
			dispatch(getUserInfo())
		}else {
			navigate('/login')
		}
		
	}
	function handleBuyNow() {
		handleAddToCart().then( () => {
			if(isMobile) {
				navigate('/cart')
			} else {
				navigate('/checkout')
			}
		})
	}
	
	const handleShare = async () => {
		if (navigator.share) {
			try {
				await navigator.share({
					title: document.title,
					url: window.location.href
				})
				console.log('Thanks for sharing!')
			} catch (err) {
				console.log(`Couldn't share because of`, err.message)
			}
		} else {
			console.log('Web Share API not supported in your browser')
		}
	}
	
	const handleWishlist = () => {
		axios.post(`${process.env.REACT_APP_API_URL}/user/wishlist/add`, {
			"userId": UserID,
			"productId": id
		}).then(r => {
			if (r.data.output.Status === "Success") {
				console.log(r.data)
			} else {
				console.log(r.data)
			}
		}).catch(e => console.log(e))
	};
	return (
		<div className=' mx-auto md:py-10 px-4 sm:px-6 lg:px-12 2xl:max-w-screen-2xl justify-center'>
			<div className='justify-center md:flex md:space-x-12 '>
				<div className=' md:flex flex-row items-center'>
					<Carousel {...prodCarouselProps} className='w-full md:order-2 lg:w-fit aspect-square max-h-[500px] mb-2 md:mb-0 md:ml-4' >
						{imgURLs.map(({url}, index) => (
							<div key={index} className='w-full aspect-square'>
								<img
									src={url}
									alt={`Product ${index + 1}`}
									className='object-contain w-full aspect-square'
								/> 
							</div>
						))}
					</Carousel>
					<div className='flex no-scrollbar overflow-scroll md:order-1 md:flex-col md:max-h-[500px] space-x-1 sm:space-x-1.5 md:space-x-0 md:space-y-2'>
						{imgURLs.map(({url}, index) => (
							<ScrollIntoViewIfNeeded
								key={index}
								active={index === selectedImageIndex}
								options={{
									behavior: "smooth",
									scrollMode: "if-needed",
									block: "nearest",
									inline: "nearest",
								}}
								className='w-16 h-16 sm:w-18 sm:h-18 md:w-20 md:h-20 aspect-square '
							>
								<img
									src={url}
									alt={`Thumbnail ${index + 1}`}
									onClick={() => setSelectedImageIndex(index)}
									className={`w-full aspect-square rounded-md object-contain cursor-pointer ${
										selectedImageIndex === index
											? "border-2 border-blue-500"
											: ""
									}`}
								/>
							</ScrollIntoViewIfNeeded>
						))}
					</div>
				</div>
				<div className='pt-4 md:pt-2'>
					<h1 className='text-xl sm:text-2xl md:text-3xl uppercase font-montserrat font-semibold text-blackish'>
						{ProductName}
					</h1>
					<div className='text-sm md:text-base flex items-center gap-2'>
						<div className='text-gray-500'>Brand:</div>
						<div className='text-blackish'>{BrandName}</div>
					</div>
					<div className='flex items-center mt-1 sm:mt-2 md:mt-3 space-x-1.5 md:2'>
						<div
							className='text-heading font-bold text-xl md:text-2xl'>
							₹ {Intl.NumberFormat('en-IN').format(Math.round(Price - (Price * (Discount / 100))))}
						</div>
						<span
							className='font-segoe text-lg md:text-xl text-gray-400 line-through'>
								{Price}
						</span>
						<span className='text-lg md:text-xl text-green-600'>
							{Discount}% OFF
						</span>
					</div>
					{/*
					<div className='flex space-x-3 items-center mt-4 text-gray-600'>
						<div>
							Color:
						</div>
						<div>
							<fieldset className="flex flex-wrap gap-3">
								<legend className="sr-only">Color</legend>
								<div>
									<input
										type="radio"
										name="ColorOption"
										value="ColorBlack"
										id="ColorBlack"
										className="peer hidden"
										defaultChecked
									/>
									
									<label
										htmlFor="ColorBlack"
										className="block h-8 w-8 cursor-pointer rounded-full bg-black shadow-sm peer-checked:ring-2 peer-checked:ring-black peer-checked:ring-offset-2"
									>
										<span className="sr-only"> Texas Tea </span>
									</label>
								</div>
								
								<div>
									<input type="radio" name="ColorOption" value="ColorRed" id="ColorRed"
									       className="peer hidden"/>
									
									<label
										htmlFor="ColorRed"
										className="block h-8 w-8 cursor-pointer rounded-full bg-red-500 shadow-sm peer-checked:ring-2 peer-checked:ring-red-500 peer-checked:ring-offset-2"
									>
										<span className="sr-only">Fiesta Red</span>
									</label>
								</div>
								
								<div>
									<input type="radio" name="ColorOption" value="ColorBlue" id="ColorBlue"
									       className="peer hidden"/>
									
									<label
										htmlFor="ColorBlue"
										className="block h-8 w-8 cursor-pointer rounded-full bg-blue-500 shadow-sm peer-checked:ring-2 peer-checked:ring-blue-500 peer-checked:ring-offset-2"
									>
										<span className="sr-only">Cobalt Blue</span>
									</label>
								</div>
								
								<div>
									<input type="radio" name="ColorOption" value="ColorGold" id="ColorGold"
									       className="peer hidden"/>
									
									<label
										htmlFor="ColorGold"
										className="block h-8 w-8 cursor-pointer rounded-full bg-amber-500 shadow-sm peer-checked:ring-2 peer-checked:ring-amber-500 peer-checked:ring-offset-2"
									>
										<span className="sr-only">Goldtop</span>
									</label>
								</div>
							</fieldset>
						</div>
					</div>
					*/}
					<div className='mt-4 max-w-lg'>
						<ProductPolicies />
					</div>
					<div className='flex space-x-2 items-center mt-4 mb-3 text-gray-600'>
						<div>
							Quantity:
						</div>
						<div
							className='flex items-center h-8 sm:h-10 md:h-12 w-min rounded-md text-xl border border-gray-300'>
							<button className='h-full aspect-square' onClick={()=> (quantity > 1) && setQuantity(quantity - 1)}>-</button>
							<input type='number'
							       onChange={(e)=>setQuantity(e.target.value)}
							       value={quantity}
							       max={StockQuantity} min={1}
							       className={`${quantity> StockQuantity ? "text-red-500" : "text-black"}  hide-number-arrows h-full aspect-square text-lg text-center focus:outline-0`}/>
							<button className='h-full aspect-square' onClick={() => quantity < StockQuantity && setQuantity(quantity + 1)}>+</button>
						</div>
					</div>
					<div className='md:max-w-sm'>
						<div className='mt-3 sm:mt-4 md:mt-3 space-y-2 text-white font-semibold'>
							<button
								onClick={handleAddToCart}
								className='peer h-12 sm:h-14 w-full text-indigo-600 border border-indigo-600 uppercase flex flex-row justify-center items-center rounded-md hover:bg-indigo-600 hover:text-white transition-colors duration-300'>
									Add To Cart
								<span className='ml-2'>
									<TbShoppingCartPlus className='text-2xl'/>
								</span>
							</button>
							<button
								className='peer-hover:text-indigo-600 peer-hover:bg-white peer-hover:border h-12 sm:h-14 w-full bg-indigo-600 uppercase flex flex-row justify-center items-center rounded-md hover:bg-white hover:text-indigo-600 hover:border border-indigo-600 transition-colors duration-300'
								onClick={handleBuyNow}>
								<span>
									Buy It Now
								</span>
								<span className='ml-2'>
									<TbShoppingCartBolt className='text-2xl'/>
								</span>
							</button>
						</div>
					</div>
					<div className='md:max-w-sm flex space-x-2 mt-2 text-white'>
						<button
							onClick={handleWishlist}
							className='h-12 sm:h-14 w-full space-x-2 bg-indigo-600 flex flex-row justify-center items-center rounded-md hover:bg-white hover:text-indigo-600 hover:border border-indigo-600 transition-colors duration-300'>
							<span>
								<TbHeart className='text-xl'/>
							</span>
							<span>
								Wishlist
							</span>
						</button>
						<button
							onClick={handleShare}
							className='h-12 sm:h-14 w-full space-x-2 bg-indigo-600 flex flex-row justify-center items-center rounded-md hover:bg-white hover:text-indigo-600 hover:border border-indigo-600 transition-colors duration-300'>
							<span>
								<TbShare className='text-xl'/>
							</span>
							<span>
								Share
							</span>
						</button>
					</div>
					<div className='md:max-w-sm mt-4 sm:mt-4'>
						<div className='flex items-stretch h-14'>
							<input type='text' placeholder='Pin Code i.e 204101' className='flex-1 ps-3 border border-indigo-600 border-e-0 rounded-s-lg focus:outline-0'/>
							<button className='pe-3 bg-white text-indigo-600 border border-indigo-600 rounded-e-lg border-s-0'>Check</button>
						</div>
					</div>
				</div>
			</div>
			<div className='mt-8 md:flex justify-center'>
				<ProductDetail description={ProductDescription}/>
			</div>
		</div>
	);
};

export default Product
