import {useEffect} from "react";
import {useSelector} from "react-redux";

export const LoginSignupComp = ({setIsActiveStepCompleted, setActiveStep}) => {
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	useEffect(() => {
		isLoggedIn ? setActiveStep(1) : setActiveStep(0);
	}, [isLoggedIn]);
	return(
		<div className='flex font-semibold text-gray-400 p-2 gap-2'>
			<button className='h-14 w-32 rounded-full shadow-inner border-2 uppercase'>Register</button>
			<button className='h-14 w-32  rounded-full shadow-inner border-2 uppercase'>Log In</button>
		</div>
	)
}