import {useState} from "react";
import Section from "./Section";
import { scroller } from 'react-scroll';
const ProductDetail = ({description}) => {
	const [activeSectionIndex, setActiveSectionIndex] = useState(-1);
	const techSpecs = {
		text: "This is the Technical Specification" ,
		table:[
			{
				title: "Brand",
				content: "Brand Name"
			},
			{
				title: "Model",
				content: "Model Name"
			},
			{
				title: "Color",
				content: "Color Name"
			},
			{
				title: "Size",
				content: "Size Name"
			},
			{
				title: "Weight",
				content: "Weight Name"
			},
			{
				title: "Dimensions",
				content: "Dimensions Name"
			},
			{
				title: "Material",
				content: "Material Name"
			},
			{
				title: "Country of Origin",
				content: "Country of Origin Name"
			},
		]
	}
	
	const sections = [
		{
			title:"Description",
			content: description ?? "This is the product description section "
		},
		{
			title: "Tech Specs",
			content: techSpecs
		},
		{
			title: "Reviews",
			content: "This is the product reviews section " +
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam imperdiet pellentesque nunc, non vehicula neque. Morbi hendrerit purus nec gravida consectetur. Nulla luctus, erat eget suscipit aliquet, ante purus eleifend odio, vitae pulvinar dui dolor ut mi. Proin sit amet massa eu ligula mattis euismod. Cras auctor finibus sagittis. In quis convallis orci. In tincidunt sapien sit amet mauris placerat, et commodo velit sagittis. Nunc venenatis lorem a lacinia luctus. In elementum nulla vel vehicula dapibus. Aliquam interdum tortor a vestibulum lobortis. Suspendisse ut dolor sed nisi mollis molestie id vitae quam.Nulla efficitur auctor aliquam. Nam vel pulvinar tortor, ac iaculis metus. Cras tempus, risus et venenatis aliquet, nulla sem cursus tortor, non sagittis nisi nunc non leo. Cras vulputate id enim nec vehicula. Quisque egestas risus purus, ut dictum turpis ultrices eget. Vestibulum placerat interdum lorem, sit amet ultrices nibh mattis ut. Sed semper metus eu massa pulvinar pellentesque. Interdum et malesuada fames ac ante ipsum primis in faucibus. In vulputate, orci nec varius sagittis, mi turpis lobortis lorem, commodo feugiat nisi diam at dolor. Aliquam vitae gravida nisl. Duis ut fermentum nunc, nec feugiat ante. Quisque id tortor interdum, vestibulum augue ac, aliquam neque. Integer dapibus accumsan nulla eu efficitur. Donec fermentum ultrices aliquet.",
		},
	]
	
	const handleButtonClick = (index) => {
		scroller.scrollTo(`section-${index}`, {
			duration: 800,
			delay: 0,
			smooth: 'easeInOutQuart',
			offset: -150 // Replace 100 with the height of your sticky header
		});
		activeSectionIndex === index ? setActiveSectionIndex(-1) : setActiveSectionIndex(index)
	};
	
	return (
		<div>
			<div>
				<div className=''>
					<div className="hidden sm:block border-b border-gray-200 sticky top-[91px] bg-white">
						<div className="-mb-px flex md:gap-4">
							{sections.map((section, idx) => (
								<button
									key={idx}
									onClick={() => activeSectionIndex !== idx &&  handleButtonClick(idx)}
									className={`
									shrink-0 border-2 p-3 sm:text-lg
									 ${activeSectionIndex === idx ?
										'rounded-t-lg border-b-white text-sm font-bold text-gray-500'
										:
										'border-transparent text-sm font-medium text-gray-500 hover:text-gray-700'
									}`
									}
								>
									{section.title}
								</button>
							))}
						</div>
					</div>
					<div className=''>
						{sections.map((section, idx) => (
							// <ScrollIntoViewIfNeeded
							// 	key={idx}
							// 	active={activeSectionIndex === idx}
							// 	options={scrollIntoViewOptions}
							// >
								<Section section={section} idx={idx} activeSectionIndex={activeSectionIndex} setActiveSectionIndex={setActiveSectionIndex} />
							// </ScrollIntoViewIfNeeded>
							))}
					</div>
				</div>
			</div>
		</div>
)
}
export default ProductDetail;