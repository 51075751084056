import React, {useEffect, useState} from "react";
import axios from "axios";
import {TbShoppingCartBolt, TbShoppingCartPlus} from "react-icons/tb";
import {useNavigate, useSearchParams} from "react-router-dom";
import Filters from "../../data/Filters";
import {getUserInfo} from "../../redux/authSlice";
import {isMobile} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";

const ProductListing = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [products, setProducts] = useState(null);
	const [filters, setFilters] = useState({});
	const [searchParams] = useSearchParams();
	const searchQuery = searchParams.get('search');
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const { UserID } = useSelector((state) => state.auth.user) || {};
	// console.log(searchQuery);
	useEffect(() => {
		window.scrollTo(0, 0)
		const fetchData = async () =>{
			try {
				const {data: {NewArrivals}} = await axios.post(`${process.env.REACT_APP_API_URL}/home`, {"showProductCount": "10"})
				return NewArrivals ?? null
			}catch (e) {
				console.log(e)
			}
		}
		fetchData().then(res => setProducts(res))
	}, []);
	
	const handleFilter = (e, type) => {
		const {target: {name, checked}} = e;
		if(checked){
			setFilters({
				...filters,
				[type]: [...(filters[type] ?? []), name]
			})
		}else{
			setFilters({
				...filters,
				[type]: filters[type]?.filter(item => item !== name)
			})
		}
	  }
	
	useEffect(() => {
	}, [filters])
	
	useEffect(() => {
		if(searchQuery?.length > 3){
			const fetchData = async () =>{
				try {
					const {data:{recordset}} = await axios.post(`${process.env.REACT_APP_API_URL}/products/filter`, {"search": `%${searchQuery}%`})
					console.log(recordset)
					return recordset ?? []
				}catch (e) {
					console.log(e)
				}
			}
			fetchData().then(res => setProducts(res))
		}
	}, [searchQuery]);
	
	const handleAddToCart = async (id) => {
		if(isLoggedIn){
			const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/user/cart/add`, {
				"userId": UserID,
				"productId": id,
				"quantity": 1
			})
			if (data.output.Status === "Success") {
				navigate('/cart')
			} else {
				console.log(data)
			}
			dispatch(getUserInfo())
		}else {
			navigate('/login')
		}
		
	}
	function handleBuyNow(id) {
		handleAddToCart(id).then( () => {
			if(isMobile) {
				navigate('/cart')
			} else {
				navigate('/checkout')
			}
		})
	}
	
	
	
	return (
		<div className='mx-auto max-w-screen-sm lg:max-w-screen-xl md:px-10 2xl:p-0'>
			<div className='flex flex-col md:flex-row'>
				<aside className='w-1/5 me-5 justify-start items-start h-full border-e-2 hidden md:block sticky top-[91px]'>
					<div className='flex flex-col gap-2 p-3'>
						<h3 className='text-2xl font-bold'>Filters</h3>
						{Filters.map(({name, options}, index) => (
							<div className='flex flex-col gap-2' key={index}>
								<h4 className='text-lg font-semibold'>{name}</h4>
								<ul className='flex flex-col gap-2'>
									{options.map(({name, value},idx) => (
										<label className='flex items-center gap-2' key={idx}>
											<input type="checkbox" name={name} value={value} onChange={(e) => handleFilter(e,Filters[index].name )}/>
											<span className='text-base font-medium text-gray-900 hover:underline cursor-pointer'>{name}</span>
										</label>
									))}
								</ul>
							</div>
						))}
					</div>
				</aside>
				<div className='flex-1'>
					<h3 className='w-full text-2xl font-bold m-3 mb:py-2 mb-0 md:sticky top-[91px] bg-white '>{(searchQuery? `Searching ${searchQuery}...` : 'Showing') }</h3>
					<ul className='flex flex-col w-full'>
						{products?.map(({ProductID, ProductName, BrandName, CategoryName, SubCategoryName, Price, Discount, CoverImage}) => (
							<li key={ProductID} className='mt-4 md:mt-2 flex flex-col md:flex-row justify-between border-b-2 w-full'>
								<div className='flex flex-1 flex-row items-center gap-2'    onClick={() => navigate(`/products/
										${ProductName.toLowerCase().replace(/\s+/g, "-")}
										/dp/${ProductID}`)
								}>
									<div className='w-28 sm:w-36 max-w-md md:w-44 lg:w-60 aspect-square flex items-center justify-center object-contain' >
										<img src={CoverImage} alt={ProductName} />
									</div>
									<div className='flex max-w-md flex-col justify-between md:self-stretch'>
									<div>
										<h4 className='text-base sm:text-lg md:text-xl sm:font-medium md:mt-8 font-montserrat font-semibold text-blackish'>{ProductName}</h4>
										<span
											className='text-sm md:text-base text-gray-900 hover:underline cursor-pointer'>{BrandName}</span>
										<p className='hidden sm:block text-sm md:text-base text-gray-900'>
											<span className='hover:underline cursor-pointer'>{CategoryName}</span>
											<span
												className='text-sm text-gray-900 before:content-["/"] before:mx-0.5 hover:underline cursor-pointer hover:before:no-underline'>{SubCategoryName}</span>
										</p>
									
									</div>
									<p className='mb-2 sm:mb-0 md:mb-14 '>
										<span
											className='text-base md:text-lg text-gray-500 line-through font-segoe'>{Price}</span>
										<span
											className='mx-1  md:text-xl text-red-600 after:ms-0.5 after:content-["%"]'>{Discount}</span>
										<span
											className='mx-1 text-green-600 text-lg md:text-xl before:me-0.5 before:content-["₹"] after:content-["/-"] after:text-sm'>{Intl.NumberFormat('en-IN').format((Price - (Price * Discount / 100)).toExponential(2))}</span>
									</p>
								</div>
								</div>
								<div className='flex px-2 gap-2 md:justify-center text-sm font-semibold flex-row md:flex-col w-full xl:max-w-[250px]'>
									<button onClick={() => handleAddToCart(ProductID)}
										className='peer h-10 md:h-14 w-full max-w-xs text-indigo-600 border border-indigo-600 uppercase flex flex-row justify-center items-center rounded-md hover:bg-indigo-600 hover:text-white transition-colors duration-300'>
										Add
										<span className='ml-2'>
												<TbShoppingCartPlus className='text-2xl'/>
											</span>
									</button>
									<button onClick={() => handleBuyNow(ProductID)}
										className='peer h-10 md:h-14 w-full max-w-xs text-white bg-indigo-600 border border-indigo-600 uppercase flex flex-row justify-center items-center rounded-md hover:bg-white hover:text-indigo-600 transition-colors duration-300'>
										Buy Now
										<span className='ml-2'>
												<TbShoppingCartBolt className='text-2xl'/>
											</span>
									</button>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default ProductListing