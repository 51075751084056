import {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider, useDispatch} from "react-redux";
import store from "./redux/store";
import {checkUserLoggedIn} from "./redux/authSlice";
import {Header, NavBar, MobNavBar, Footer} from './components';
import {Home, Register, Login, Product, Cart, FileUpload, Wishlist, RegisterSeller, ProductCheckout, ProductListing} from './pages'
// import PrivateRoute from './components/PrivateRoute';
import RedirectToHomeIfLoggedIn from "./components/HOC/RedirectToHomeIfLoggedIn";

const App = () => {
	return (
		<Provider store={store}>
			<AppContent/>
		</Provider>
	);
}

const AppContent = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkUserLoggedIn());
	}, [dispatch]);
	
	
	return (
		<div className={"content-center flex flex-col min-h-svh"}>
			<BrowserRouter>
				<Header/>
				<NavBar/>
				<div className={'flex-1'}>
					<Routes>
						<Route path="/register" element={<RedirectToHomeIfLoggedIn><Register/></RedirectToHomeIfLoggedIn>}/>
						<Route path="/login" element={<RedirectToHomeIfLoggedIn><Login/></RedirectToHomeIfLoggedIn>}/>
						{/*<Route path="/add" element={<Register/>}/>*/}
						{/* <Route path="/login" element={<Login/>}/> */}
						<Route path="/" element={<Home/>}/>
						<Route path={'/products/listing'} element={<ProductListing/>}/>
						<Route path="/products/:title/dp/:id" element={<Product/>}/>
						<Route path="/seller-registration" element={<RegisterSeller/>}/>
						<Route path="/cart" element={<Cart/>}/>
						<Route path="/checkout" element={<ProductCheckout/>}/>
						<Route path="/wishlist" element={<Wishlist/>}/>
						<Route path="/upload" element={<FileUpload/>}/>
						<Route path={"*"} element={<h1>Not Found</h1>}/>
					</Routes>
				</div>
				<Footer/>
				<MobNavBar/>
			</BrowserRouter>
		</div>
	);
}

export default App;