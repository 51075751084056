import {CategoriesMenu} from "../components/NavBarSubPopUpMenus/CategoriesMenu";

export const NavbarLinks = [
	{
		text: "HOME",
		elemType: "link",
		to: "/"
	},
	{
		text: "CATEGORIES",
		elemType: "button",
		comp: CategoriesMenu
	},
	{
		text: "LAPTOPS",
		elemType: "link",
		to: "products/listing"
	},
	{
		text: "ACCESSORIES",
		elemType: "link",
		to: "products/listing"
	},
	{
		text: "ABOUT",
		elemType: "link",
		to: "products/listing"
	},
	{
		text: "HOT OFFERS",
		elemType: "link",
		to: "products/listing"
	}
]